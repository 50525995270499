import SectionHeader from '../components/sections/header';
import SectionBreadcrumbs from '../components/sections/breadcrumbs';
import SectionPost from '../components/sections/post';
import SectionFooter from '../components/sections/footer';

import React from 'react';
import { graphql } from 'gatsby';

import { IPage } from '../models/page.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IPost } from '../models/post.model';
import { ISetting } from '../models/setting.model';

import { getNodes } from '../utils/get-nodes';
import getPageConfigFromSections from '../utils/get-page-config-from-sections';
import getCustomStyleFromSetting from '../utils/get-custom-style-from-settings';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';
import UpwardsButton from '../components/molecules/upwards-button';
import ThemeStyle from '../components/atoms/theme-style';

interface IPostProps {
    readonly data: {
        page: IPage;
        post: IPost;
        allThemeSetting: IQueryAllResult<ISetting>;
    };
}

const Post: React.FC<IPostProps> = ({ data }) => {
    const { page, post, allThemeSetting } = data;
    const themeSettings = getNodes(allThemeSetting);
    const customStyle = getCustomStyleFromSetting(themeSettings);

    const sections = page.sections.map((section) => {
        return section.type === 'post'
            ? { ...section, settings: { ...section.settings, isMain: true } }
            : section;
    });
    const { mainSectionIndex } = getPageConfigFromSections(sections);

    const sectionsWithData = sections.map((section) => {
        if (section.type === 'post') {
            return {
                ...section,
                extendedItems: {
                    post: post,
                },
            };
        }
        return section;
    });

    return (
        <MainLayout>
            <ThemeStyle style={customStyle} />
            {sectionsWithData.map((section, index) => {
                return (
                    <SectionFactory
                        key={`section-${section.type}-${section.sectionId}`}
                        SectionComponent={sectionComponents[section.type]}
                        section={section}
                        TitleTag={mainSectionIndex === index ? 'h1' : 'h4'}
                    />
                );
            })}
            <UpwardsButton />
        </MainLayout>
    );
};

export const query = graphql`
    query ($pageId: Int!, $postId: Int!, $locale: String!) {
        page(pageId: { eq: $pageId }) {
            sections {
                ...sectionFields
            }
        }
        post(postId: { eq: $postId }, locale: { eq: $locale }) {
            title
            lead
            content
            publishedAt
            displayPublishedAt
            mainCategory {
                title
                pathname
            }
            tags {
                tagId
                name
                pathname
            }
            author {
                profileId
                displayName
                locale
                description
                media {
                    ...mediaFields
                }
            }
            media {
                ...mediaFields
            }
        }
        allThemeSetting {
            edges {
                node {
                    name
                    value
                }
            }
        }
    }
`;

export default Post;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header': SectionHeader,
    'breadcrumbs': SectionBreadcrumbs,
    'post': SectionPost,
    'footer': SectionFooter,
};